import * as React from "react";
import {useIntl} from "react-intl";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Loader from "../components/loader";
import pageNotFound from "../images/pageNotFound.svg";
import pageNotFoundDark from "../images/pageNotFoundDark.svg";
import {useColorMode} from "theme-ui";
import {useLocalization} from "gatsby-theme-i18n";

const NotFoundPage = (props) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const [colorMode] = useColorMode()
    const isDark = colorMode === `dark`

    const {locale} = useLocalization()

    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return (<Loader/>)
    }
    return (
        <Layout location={props.location} color='#FF1453'>
            <SEO title={f("notFound")} lang={locale} description={f("descriptionMeta")}/>
            <div className='flex flex-col items-center px-6 md:px-14'>
                <p className='font-ExtraBold text-5xl xl:text-6xl'>
                    {f("page")} <span className='text-red'>{f("not")} </span>{f("found")}<span
                    className='text-red'>.</span>
                </p>
                <img src={isDark ? pageNotFoundDark : pageNotFound} alt='404' className='h-auto w-full md:w-1/2 py-8' />
            </div>
        </Layout>
    );
};
export default NotFoundPage;
